import { useTimer } from 'react-timer-hook';
import { FaInstagram, FaSnapchat, FaTiktok, FaMapMarker, FaPhone, FaEnvelope } from 'react-icons/fa';
import vid from './open.mp4';
import logo from './logo.png';

const LAUNCHING_DATE = "02/20/2024";

function App() {
  const { seconds, minutes, hours, days } = useTimer({ expiryTimestamp: new Date(LAUNCHING_DATE).getTime(), onExpire: () => { } });

  return (
    <div className={`h-screen relative lg:overflow-hidden`}>

      <div className='absolute inset-0'>
        <video src={vid} autoPlay alt="video" loop muted playsInline
          className="h-full w-full object-cover">
        </video>
      </div>

      <div className='absolute inset-0 bg-black/30'>

      </div>

      <div className='absolute top-12 left-1/2 -translate-x-1/2'>
        <img src={logo} alt='logo' className='w-56' />
      </div>


      <div className="absolute text-center top-[35%] left-1/2 -translate-x-1/2">
        <div>
          <p className="text-2xl lg:text-4xl text-white font-thin uppercase lg:ml-4 tracking-widest lg:tracking-[1.3rem]">Our  website  is</p>
          <p className="text-3xl lg:text-7xl text-white font-bold uppercase lg:tracking-widest">coming soon</p>
        </div>

        <div className='flex flex-row text-white text-sm lg:text-2xl my-10 justify-center items-center uppercase gap-10'>
          <div className='space-y-5'>
            <p className='font-thin'>Days</p>
            <p className='text-[#f0c020]'>{days}</p>
          </div>
          
          <div className='space-y-5'>
            <p className='font-thin'>Hours</p>
            <p className='text-[#f0c020]'>{hours}</p>
          </div>

          <div className='space-y-5'>
            <p className='font-thin'>Minutes</p>
            <p className='text-[#f0c020]'>{minutes}</p>
          </div>


          <div className='space-y-5'>
            <p className='font-thin'>Seconds</p>
            <p className='text-[#f0c020]'>{seconds}</p>
          </div>
        </div>
      </div>




      <div className="absolute bottom-5 left-0 right-0 font-thin">
        <div className="grid grid-cols-1 gap-5 lg:gap-0 lg:grid-cols-3 place-items-center lg:place-items-stretch items-start px-5 text-white">

          <div className='col-span-1'>
            <p className='uppercase'> <FaMapMarker className='inline-block mx-2' />KSA, Riyadh, Takhasosi</p>
            <a href='mailto:info@brightlight.com.sa'><p className='normal-case'><FaEnvelope className='inline-block mx-2' />info@brightlight.com.sa</p></a>
          </div>

          <div className='col-span-1 z-20'>
            <p className='uppercase text-center'>Find us below</p>
            <div className='flex flex-row items-center justify-center gap-5 py-2'>
              <a href='tel:+966951654111'><FaPhone size={20} /></a>
              <a href='https://instagram.com/brightlight_ksa?igshid=YTQwZjQ0NmI0OA==' target='_blank' rel='noreferrer'><FaInstagram size={20} /></a>
              <a href='https://www.tiktok.com/@brightlight_ksa?_t=8gzi18Bks6y&_r=1' target='_blank' rel='noreferrer'><FaTiktok size={20} /></a>
              <a href='https://www.snapchat.com/add/brightlight_ksa?share_id=EGehGI-5Kuk&locale=ar-SA' target='_blank' rel='noreferrer'><FaSnapchat size={20} /></a>
            </div>
          </div>

          <div className='col-span-1'>
            <p className='text-right tracking-wider'>شـــركــة  الــمــتــحـدون  لـلإضــاءة  الــتجــاريــــــة</p>
            <p className='text-right uppercase text-sm tracking-widest'>united company for commerical lighting</p>
          </div>
        </div>
      </div>


    </div>
  );
}

export default App;
